import {
  CalendarIcon,
  ClockIcon,
  CogIcon,
  CurrencyDollarIcon,
  FolderIcon,
  HomeIcon,
  LightningBoltIcon,
  LocationMarkerIcon,
  MapIcon,
  OfficeBuildingIcon,
  RefreshIcon,
  TruckIcon,
  UsersIcon,
} from "@heroicons/react/outline";

const links = [
  {
    label: "Home",
    href: "/dashboard",
    icon: HomeIcon,
  },
];

const publicLinks = [
  // {
  //   label: "Industries",
  //   href: "/industries",
  // },
  {
    label: "Home",
    href: "/",
  },
  {
    label: "View Platforms",
    href: "/platforms",
  },
];

const bottomLinks = [
  {
    label: "Integrations",
    href: "/integrations",
    icon: LightningBoltIcon,
  },
  {
    label: "Settings",
    href: "/settings",
    icon: CogIcon,
  },
];

export { bottomLinks, publicLinks };

export default links;

import React from "react";
import BaseButton from "../BaseButton";

const SecondaryButton = ({
  size,
  children,
  onClick,
  href,
  className,
  disabled,
  fullWidth,
}) => {
  return (
    <BaseButton
      size={size}
      onClick={onClick}
      className={className}
      href={href}
      disabled={disabled || false}
      variant={"secondary"}
      fullWidth={fullWidth}
    >
      {children}
    </BaseButton>
  );
};

export default SecondaryButton;

import BaseButton from "../BaseButton";

const PrimaryButton = ({
  size,
  children,
  onClick,
  href,
  disabled,
  className,
  fullWidth,
  type,
}) => {
  return (
    <BaseButton
      size={size}
      onClick={onClick}
      fullWidth={fullWidth}
      href={href}
      type={type}
      variant={"primary"}
      disabled={disabled || false}
      className={className}
    >
      {children}
    </BaseButton>
  );
};

export default PrimaryButton;
